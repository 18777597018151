import {useQuery, useMutation} from "@apollo/client";
import GET_MY_OFFICE_CHANNELS from "../../gql/queries/GET_MY_OFFICE_CHANNELS";
import {useEffect, useState} from "react";
import {Button, message, Popconfirm} from "antd";
import DISABLE_OFFICE_CHANNEL_MUTATION from "../../gql/mutations/DISABLE_OFFICE_CHANNEL_MUTATION";
import OfficeChannelCreator from "../OfficeChannelCreator/OfficeChannelCreator.component";


type props_type = {
    officeId: String|undefined
}
type channel_type = {
    node:{
        channel:{
            id: String,
            name: String
        },
        route: {
            id: String,
            name: String,
            routeType: String
        }
        getPriceProperty: String
    }
}

const OfficeChannels = ({officeId}:props_type) =>{
    const {data, loading,error, refetch} = useQuery(GET_MY_OFFICE_CHANNELS, {variables:{"id":officeId}})
    const [disableOfficeChannelMutation, {data: mutation_data, loading: mutation_loading, error: mutation_error}] = useMutation(DISABLE_OFFICE_CHANNEL_MUTATION)

    const [channels, setChannels] = useState<channel_type[]>([])

    useEffect(() => {
        if(mutation_data){
            refetch()
            message.success("Wyłączono kanał")
        }
    }, [mutation_data]);

    useEffect(() => {
        if(data){
            setChannels(data.salesman.getMyOffice.channelgroupSet.edges)
        }
    }, [data]);

    const handleDisableChannel = (channel_id:any) =>{
        disableOfficeChannelMutation({
            variables:{
                input:{
                    officeId: officeId,
                    channelId: channel_id
                }
            }
        })
    }

    return(
        <table>
            <thead>
            <tr>
                <td>Nazwa</td>
                <td>Typ trasy</td>
                <td>Cena</td>
            </tr>
            </thead>
            {channels.map(
                (channel:channel_type)=><tr>
                    <td>
                        {channel.node.channel.name}
                    </td>
                    <td>{channel.node.route.routeType}</td>
                    <td>
                        {channel.node.getPriceProperty} PLN
                    </td>
                    <td>
                        <Popconfirm
                            title="Usuwasz kanał, czy na pewno?"
                            okText={"Tak"}
                            cancelText={"Nie"}
                            onConfirm={()=>handleDisableChannel(channel.node.channel.id)}>
                            <Button danger={true}>Wyłącz kanał</Button>
                        </Popconfirm>
                        </td>
                    </tr>
            )}
            <tr>
                <td><OfficeChannelCreator officeId={officeId} refetch_channels={refetch} /></td>
            </tr>
        </table>
    )
}

export default OfficeChannels