import {gql} from "@apollo/client";

const CREATE_OR_UPDATE_ALLOWED_MESSAGE_DESTINATION = gql`
  mutation CreateOrUpdateAllowedMessageDestination($input: CreateOrUpdateAllowedMessageDestinationInput!) {
    createOrUpdateAllowedMessageDestination(input: $input) {
      allowedMessageDestination {
        id
        landlineNumbers
        allowedCountries {
            name,
            code
        }
      }
    }
  }
`;

export default CREATE_OR_UPDATE_ALLOWED_MESSAGE_DESTINATION