import React, {useState, useEffect} from "react";
import {Input, Button, Form, Checkbox} from "antd";
import {useMutation} from "@apollo/client";
import Cookies from 'universal-cookie';
import TOKEN_AUTH from "../../gql/mutations/TOKEN_AUTH";
import {useNavigate} from "react-router";

type LoginFormType = {
    username?: string;
    password?: string;
    remember?: string
}

const LoginLayout = () =>{
    const navigate = useNavigate()

    const cookies = new Cookies()
    const [jwttokenCookies, setJwttokenCookies] = useState(cookies.get('token'));
    const [jwttoken, setJwttoken] = useState(localStorage.getItem('token'));
    const [tokenAuth, {data, loading, error}] = useMutation(TOKEN_AUTH)
    const [remember, setRemember] = useState(false)
    const loginButtonHandler = (e:any) =>{
        if(e.username && e.password)
            tokenAuth({variables:{username: e.username, password: e.password}})
        if(e.remember)
            setRemember(e.remember)
    }


    useEffect(() => {
        if(jwttokenCookies){
            localStorage.setItem('token', jwttokenCookies)
            setJwttoken(jwttokenCookies)
            navigate('/')
        }
        if(jwttoken){
            navigate('/')
        }
    }, []);

    useEffect(() => {
        if(jwttoken){
            navigate('/')
        }
    }, [jwttoken]);

    useEffect(()=>{
        if(data){
            localStorage.setItem('token', data.tokenAuth.token)
            if(remember){
                cookies.set('token', data.tokenAuth.token)
                setJwttokenCookies(data.tokenAuth.token)
            }
            setJwttoken(data.tokenAuth.token)
        }
    },[data])

    return(
        <div style={{backgroundColor: "#102737", height:"100vh", display: "flex", justifyContent:"center", alignItems:"center"}}>
            <div style={{color:"white", display:"flex", flexDirection:"column"}}>
                <div style={{alignItems:"flex-end", flexDirection:"row", display:"flex", }}>
                        <a href="/" className="logo"><img src="/static/img/n/navbar_logo.png" /></a>
                        <h2 style={{paddingLeft:"2px"}}>Salesman 2.0</h2>
                </div>
                <div style={{flexDirection: "column", display:"flex"}}>
                    <Form onFinish={loginButtonHandler}>
                        <div>
                            <Form.Item<LoginFormType> name="username">
                                <Input placeholder={"Login"} style={{maxWidth: "500px"}}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item name="password">
                                <Input.Password placeholder={"Hasło"} style={{maxWidth: "500px"}}/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item<LoginFormType>
                                name="remember"
                                valuePropName="checked"
                            >
                                <Checkbox style={{color: "white"}}>Zapamiętaj mnie</Checkbox>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item<LoginFormType>>
                                <Button htmlType={"submit"} onClick={loginButtonHandler}>Zaloguj</Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default LoginLayout