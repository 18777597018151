import React, {useState, useEffect} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {Table, Button, Popconfirm, Input} from "antd";
import {Link} from "react-router-dom";
import {PlayCircleOutlined, DeleteOutlined} from "@ant-design/icons"
import GET_DEDICATED_RESERVATIONS from "../../gql/queries/GET_DEDICATED_RESERVATIONS";
import CANCEL_RESERVATION_MUTATION from "../../gql/mutations/CANCEL_RESERVATION_MUTATION";
import DedicatedNumbersShowNumber from "../DedicatedNumbersShowNumber/DedicatedNumbersShowNumber.component";
import DedicatedNumbersConfirmModal from "../DedicatedNumbersConfirmModal/DedicatedNumbersConfirmModal.component";


const DedicatedNumbersList = () =>{

    const [dedicatedNumbers, setDedicatedNumbers] = useState([])
    const [allDedicatedNumbers, setAllDedicatedNumbers] = useState([])
    const {data, loading, error, refetch} = useQuery(GET_DEDICATED_RESERVATIONS)
    const [searchString, setSearchString] = useState("")

    const [cancelReservationMutation, {data: data_cancel, loading: loading_cancel, error: error_cancel}] = useMutation(CANCEL_RESERVATION_MUTATION)

    const columns = [
        {
            title:"Status",
            dataIndex:"status",
            key:"status",
            filters: [
                {
                    text: "Wstępnie zarezerwowany",
                    value: "PREADVISED"
                },
                {
                    text: "Aktywny",
                    value: "ACTIVE"
                },
                {
                    text: "Niekatywny",
                    value: "INACTIVE"
                },
                {
                    text: "Wygasły",
                    value: "EXPIRED"
                },

            ],
            onFilter: (value: any, record:any) => record.status == value,
        },
        {
            title:"Numer telefonu",
            dataIndex:"number",
            key:"number",
            render: (_:any, record:any)=><><DedicatedNumbersShowNumber key={record.id} record={record} /></>
        },
        {
            title:"Klient",
            dataIndex:"office",
            key:"office",
            render: (_:any,record:any)=><Link to={`/office_edit/${record.office.id}`}>{record.office.name} ({record.office.shortname})</Link>
        },
        {
            title:"Zakres czasowy",
            dataIndex:"date_range",
            key:"date_range",
            render: (_:any, record:any)=><>{record.start} - {record.end}</>
        },
        {
            title:"Cena",
            dataIndex:"price",
            key:"price"
        },
        {
            title: "Akcje",
            dataIndex:"actions",
            key:"actions",
            render: (_:any,record:any)=><>
                {(record.status=="ACTIVE"|| record.status=="PREADVISED" || record.status =="FAILED"|| record.status=="EXPIRED"|| record.status=="WAITING")&&
                    <Popconfirm
                        key={record.id}
                        title={"Czy na pewno chcesz usunąć ?"}
                        description={<>
                            Wyłączasz usługe numeru dedykowanego dla klienta: 
                            {record.office.name}
                            Zarezerwowany numer: <DedicatedNumbersShowNumber key={record.id}  record={record} />
                        </>}
                        onConfirm={()=>cancelReservationMutation(
                        {
                                    variables: {
                                        input:{
                                            "reservationId": record.id
                                        }
                                    }
                                }
                        )
                    }
                    >
                        <Button danger={true} type="primary">
                            <DeleteOutlined />Usuń
                        </Button>
                    </Popconfirm> }
                {(record.status == "PREADVISED"|| record.status == "FAILED" || record.status=="EXPIRED") && <DedicatedNumbersConfirmModal record={record} refetch={refetch} /> }
            </>
        }
    ]

    useEffect(() => {
        if(data){
            setDedicatedNumbers(data.salesman.getDedicatedReservations)
        }
    }, [data]);

    useEffect(() => {
        let reservations_filtered = allDedicatedNumbers.filter((reservation:any)=>{
                let status=false
                if(reservation.channel && status==false){
                    status = reservation.channel.name.toUpperCase().includes(searchString.toUpperCase())
                }
                if(reservation.office && status==false){
                    if(status==false){
                        status = reservation.office.name.toUpperCase().includes(searchString.toUpperCase())
                    }
                    if(status==false){
                        status = reservation.office.shortname.toUpperCase().includes(searchString.toUpperCase())
                    }
                    if(status==false){
                        status = reservation.office.displayName.toUpperCase().includes(searchString.toUpperCase())
                    }
                }
                if(reservation.office.dedicatedChannel && status==false){
                    status = reservation.office.dedicatedChannel.name.toUpperCase().includes(searchString.toUpperCase())
                }
                return status
            }
        )
        setDedicatedNumbers(reservations_filtered)
    }, [searchString]);

    useEffect(() => {
        if(data_cancel){
            if(data_cancel.cancelReservation.canceled){
                refetch()
            }
        }
    }, [data_cancel]);

    return(
        <>
        <Input placeholder={"Wyszukaj rezerwację"} onChange={(e)=>setSearchString(e.target.value)} />
        <Table columns={columns} dataSource={dedicatedNumbers} loading={loading} />

        </>
    )
}

export default DedicatedNumbersList