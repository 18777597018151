import {gql} from "@apollo/client";

const GET_OFFICE_TYPES = gql`
query{
    salesman{
    getOfficeTypes{
        id,
        codename,
        otype
      }
    }
}
`

export default GET_OFFICE_TYPES