import {gql} from "@apollo/client";

const GET_OFFICE_ALLOWED_MESSAGE_DESTINATION = gql`
  query GetOfficeAllowedDestination($officeId: ID!) {
    salesman {
      getOfficeAllowedDestination(officeId: $officeId) {
        id
        landlineNumbers
        allowedCountries {
          name
          code
        }
      }
    }
  }
`

export default GET_OFFICE_ALLOWED_MESSAGE_DESTINATION