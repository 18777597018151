import {gql} from "@apollo/client";

const CANCEL_RESERVATION_MUTATION = gql`
mutation($input:CancelReservationInput!){
  cancelReservation(input:$input){
    canceled
  }
}
`

export default CANCEL_RESERVATION_MUTATION