import {gql} from "@apollo/client";

const GET_ALL_MODULES = gql`
query{
  salesman{
    getAllModules {
      id,
      name,
      techName,
    }
  }
}
`
export default GET_ALL_MODULES