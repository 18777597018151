import {gql} from "@apollo/client";

const CREATE_DEDICATED_RESERVATION_MUTATION = gql`
mutation($input:CreateDedicatedReservationInput!){
  createDedicatedReservation(input:$input){
    created
  }
}
`

export default CREATE_DEDICATED_RESERVATION_MUTATION