import {gql} from "@apollo/client";

const GET_MY_OFFICE_MODULES = gql`
query ($id: ID!) {
  salesman {
    getMyOffice(id: $id) {
      id
      status
      name
      modulepriceSet {
        edges {
          node {
            id,
            module {
              id,
              name
            }
          }
        }
      }
    }
  }
}
`

export default GET_MY_OFFICE_MODULES