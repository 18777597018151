import {gql} from "@apollo/client";

const CHANGE_OFFICE_STATUS_MUTATION = gql`
mutation($input: ChangeOfficeStatusInput!){
  changeOfficeStatus(input:$input){
    changed
  }
}
`

export default CHANGE_OFFICE_STATUS_MUTATION