import {gql} from "@apollo/client";

const ENABLE_DISABLE_MODULE = gql`
mutation($input: ModulePriceTypeMutationInput!)
{
  enableDisableModule(input: $input){
    changed
  }
}
`

export default ENABLE_DISABLE_MODULE