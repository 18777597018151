import {gql} from "@apollo/client";

const GET_ALL_OFFICES = gql`
query(
$offset: Int, 
$first:Int,
$status: String
$multi: String,
$officeType: String,
$paymentType: String
){
  salesman{
    getAllOffices(
    offset: $offset, 
    first:$first, 
    status: $status,
    multi: $multi,
    officeType_Codename: $officeType,
    paymentType: $paymentType
    ){
    totalCount
      edges{
        node{
          id,
          status,
          name,
          displayName,
          nip,
          country,
          street,
           zip,
          city,
          telephone,
          fax,
          email,
          mobile,
          logo,
          registrationTemplate,
          postRegistrationTemplate,
          shortname,
          createdAt,
          paymentType,
          accountBalance,
          officeType {
            id,
            codename,
            otype,
          }
        }
      }
    }
  }
}
`
export default GET_ALL_OFFICES