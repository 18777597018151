import GET_ALL_MODULES from "../../gql/queries/GET_ALL_MODULES";
import {useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import {Table, Switch} from "antd";
import type { ColumnsType } from 'antd/es/table';
import GET_MY_OFFICE_MODULES from "../../gql/queries/GET_MY_OFFICE_MODULES";

import OfficeModulesSwitchComponent from "../OfficeModulesSwitch/OfficeModulesSwitch.component";
import OfficeModuleVotingsPro from "../OfficeExpandSettings/OfficeExpandSettings.component";
import OfficeExpandSettings from "../OfficeExpandSettings/OfficeExpandSettings.component";


type props_type = {
    officeId: string|undefined
}

type module_type = {
    id: string,
    name: string,
    techName: string,
    enabled: Boolean|undefined,
}
const OfficeModules = ({officeId}:props_type) =>{
    const {data:all_modules_data, loading, error} = useQuery(GET_ALL_MODULES)
    const {data: office_modules_data, loading: office_modules_loading, error: office_modules_error, refetch: office_modules_refetch} = useQuery(GET_MY_OFFICE_MODULES, {variables:{"id": officeId}})
    const [officeModules, setModules] = useState<module_type[]>([])
    const columns_table = [
        {
            title: "Nazwa modułu",
            dataIndex: "name",
            render:(_:any,record:module_type)=><>{record.name}</>
        },
        {
            title: "Akcje",
            dataIndex: "action",
            render:(_:any,record:module_type)=><OfficeModulesSwitchComponent refetch={office_modules_refetch} office_modules={office_modules_data} module={record} officeId={officeId}/>
        },
    ]

    const expandable_modules_tech_names = [
        "voting_cards",
        "votings",
    ]

    useEffect(() => {
        if(all_modules_data){
            setModules(all_modules_data.salesman.getAllModules)
        }
    }, [all_modules_data]);

    return(
        <>
            <Table
                columns={columns_table}
                dataSource={officeModules}
                pagination={{
                    pageSizeOptions:[
                        10,
                        20
                    ],
                    pageSize: 100
                }}
                expandable={{
                    rowExpandable: (record) => expandable_modules_tech_names.includes(record.techName),
                    expandedRowRender: (record) => <p style={{ margin: 0 }}><OfficeExpandSettings module={record} office_modules={office_modules_data} officeId={officeId} /></p>,
                }}
            />
        </>
    )
}

export default OfficeModules