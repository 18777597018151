import {gql} from "@apollo/client";

const CREATE_OFFICE_MUTATION = gql`
mutation($input: CreateOfficeMutationInput!){
createOfficeMutation(input:$input){
    created
  }
}
`

export default CREATE_OFFICE_MUTATION