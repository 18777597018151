import React, {createContext, useState, ReactNode} from "react";

export const UserContext: React.Context<any> = createContext<any>({
    salesman: null,
    setSalesman: ()=>null
})

export const UserContextProvider = ({children}: {children: ReactNode}) =>{

    const [salesman,setSalesman] = useState("")

    const value={
        setSalesman,
        salesman
    }

    return(
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    )

}
