import {Button, Modal, Input, Form, InputNumber, Select, message} from "antd";
import {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import CREATE_OFFICE_CHANNEL_MUTATION from "../../gql/mutations/CREATE_OFFICE_CHANNEL_MUTATION";

const OfficeChannelCreator = ({officeId, refetch_channels}:any) =>{
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [createOfficeChannelMutation, {data, loading, error}] = useMutation(CREATE_OFFICE_CHANNEL_MUTATION)

    const [form] = Form.useForm()
    const showModal = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        if(data){
            message.success("Utworzono nowy kanał")
            refetch_channels()
        }
    }, [data]);

    const handleOk = () => {
        form.validateFields().then(
            (data)=>{
                let data_json = {...data}
                data_json.officeId = officeId
                createOfficeChannelMutation({
                    variables:{
                        input:data_json
                    }
                })
                setIsModalOpen(false)
            }
        ).catch(
            (errorInfo)=>{
                console.log(errorInfo)
                message.error("Uzupełnij dane")
            }
        )
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Button  type="primary" onClick={showModal}>Utwórz nadpis</Button>
            <Modal title="Tworzenie nowego kanału" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    labelCol={{span:6}}
                    form={form}
                >
                    <Form.Item label={"Nadpis"} name={"name"} rules={[
                        {
                            required:true,
                            validator: (_,value) => {
                                if(value.slice(0,1)===" ")
                                    return Promise.reject(new Error('Na początku jest niedozwolony znak spacji'))
                                if(value.slice(-1)===" ")
                                    return Promise.reject(new Error('Na końcu jest niedozwolony znak spacji'))
                                return Promise.resolve()
                            }
                        },
                    ]}>
                        <Input maxLength={11} />
                    </Form.Item>
                    <Form.Item label={"Profil"} rules={[{required:true}]} name={"profile"}>
                        <Select
                        options={[
                            {
                                label:"t_mobile",
                                value:"219"
                            },
                            {
                                label:"mobitex",
                                value:"119"
                            },
                            {
                                label:"infobip",
                                value:"84"
                            },
                            {
                                label:"Orange_hurt_zagranica",
                                value:"367"
                            },
                            {
                                label:"t_mobile_zagranica",
                                value:"225"
                            },
                        ]}
                        />
                    </Form.Item>
                    <Form.Item label={"Cena kanału"}  rules={[{required:true}]} name={"price"}>
                        <InputNumber style={{width:"100%"}} step={0.01} max={1000} />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default OfficeChannelCreator