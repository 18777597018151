import {gql} from "@apollo/client";

const GET_OFFICES_FOR_RESERVATION_DEDICATED_NUMBERS = gql`
query{
  salesman{
    getOfficesForReservationDedicatedNumbers{
      id,
      shortname,
      displayName
    }
  }
}
`

export default GET_OFFICES_FOR_RESERVATION_DEDICATED_NUMBERS