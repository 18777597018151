import {gql} from "@apollo/client";

const GET_OFFICE_TEMPLATES = gql`
query{
    salesman{
      getOfficeTemplates{
        id,
        partnerCode,
        affiliate {
          id
        },
        startingBalance,
        partnerCode,
        affiliate{
          id
        }
        routeName,
        modules{
          edges{
            node{
              techName
            }
          }
        },
      }
    }
}
`

export default GET_OFFICE_TEMPLATES;