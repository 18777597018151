import {useEffect, useState} from "react";

const DedicatedNumbersShowNumber = ({record}:any)=>{
    const [number, setNumber] = useState("")

    useEffect(() => {
        if(record?.channel){
            setNumber(record.channel.msisdn ?? record.channel.name)
        }
    }, []);
    return <>{number}</>
}

export default DedicatedNumbersShowNumber