import React from 'react';
import './App.css';
import {privateRouter} from "./routers/PrivateRouter";
import {RouterProvider} from "react-router-dom";
import {ApolloClient, InMemoryCache, ApolloProvider, createHttpLink} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import {UserContextProvider} from "./contexts/UserContext.context";

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_MPROFI_GRAPHQL_API,
});
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `JWT ${token}` : "",
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
})


function App() {
  return (
      <UserContextProvider>
          <ApolloProvider client={client}>
                    <div className="App">
                         <RouterProvider router={privateRouter} />
                    </div>
          </ApolloProvider>
      </UserContextProvider>
  );
}

export default App;
