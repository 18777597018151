import {gql} from "@apollo/client";

const UPDATE_DEDICATED_RESERVATION_MUTATION = gql`
mutation($input:UpdateDedicatedReservationInput!){
  updateDedicatedReservation(input:$input){
    updated,
    message
  }
}
`

export default UPDATE_DEDICATED_RESERVATION_MUTATION