import {Button, Form, Modal, Input, InputNumber, Select, DatePicker, message} from "antd";
import {PlayCircleOutlined} from "@ant-design/icons";
import {useState, useEffect} from "react";
import {useMutation} from "@apollo/client";
import UPDATE_DEDICATED_RESERVATION_MUTATION from "../../gql/mutations/UPDATE_DEDICATED_RESERVATION_MUTATION";

const DedicatedNumbersConfirmModal = ({button_label, record, refetch}:any) =>{
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [officeName, setOfficeName] = useState("")
    const [officeShortName, setOfficeShortName] = useState("")
    const [channelName, setChannelName] = useState("")
    const [price, setPrice] = useState(150)

    const [updateDedicatedReservation, {data, loading, error}] = useMutation(UPDATE_DEDICATED_RESERVATION_MUTATION)

    const [form] = Form.useForm()

    const showModal = () => {
        setChannelName(record.channel.name)
        setOfficeName(record.office.name)
        setOfficeShortName(record.office.shortname)
        setIsModalOpen(true);
        setPrice(record.price)
    };

    useEffect(() => {
        if(data){
            if(data.updateDedicatedReservation.updated==false){
                if(data.updateDedicatedReservation.message){
                    message.error(data.updateDedicatedReservation.message)
                }else{
                    message.error("Nie uruchomiono numeru dedykowanego")
                }
            }else{
                if(data.updateDedicatedReservation.message){
                    message.success(data.updateDedicatedReservation.message)
                }else{
                    message.success("Uruchomiono numer dedykowany")
                }
            }
            refetch()
        }
    }, [data]);

    const handleOk = () => {
        form.validateFields().then(
            (values)=>{
                values.dedicatedReservationId = record.id
                if(record.status=="EXPIRED"){
                    values.typeRequest = "RENEW"
                }else if(button_label=='Edytuj'){
                    values.typeRequest = "EDIT"
                }else{
                    values.typeRequest = "ACTIVATE"
                }
                updateDedicatedReservation({
                    variables:{
                        input:values
                    }
                })
                setIsModalOpen(false);
            }
        ).catch(
            (error)=>{
                console.log(error)
            }
        )
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return(
        <>
            <Modal
                title={"Uruchomienie numeru dedykowanego"}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form labelCol={{span:8}} form={form} initialValues={{price:price, smsPrice:0.15}}>
                    <Form.Item label={"Klient"}>
                        <Input value={officeName} disabled={true} />
                    </Form.Item>
                    <Form.Item label={"Kod konta"}>
                        <Input value={officeShortName} disabled={true} />
                    </Form.Item>
                    <Form.Item label={"Kanał wysyłki"}>
                        <Input value={channelName} disabled={true} />
                    </Form.Item>
                    <Form.Item label={"Plan"} name={"chargeType"} rules={[{required: true}]}>
                        <Select
                            options={[
                                {
                                    label:"Odnawialny",
                                    value: "ONEOFF"
                                },
                                {
                                    label:"Jednorazowy",
                                    value: "RECURRING"
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item label={"Okres rozliczeniowy"} name={"chargePeriod"}  rules={[{required: true}]}>
                        <Select
                            options={[
                                {
                                    label:"Co 6 miesięcy",
                                    value: "MONTHS6"
                                },
                                {
                                    label:"Co 12 miesięcy",
                                    value: "MONTHS12"
                                },
                                {
                                    label:"Co 24 miesiące",
                                    value: "MONTHS24"
                                },
                                {
                                    label:"Jednodniowy",
                                    value: "DAYS1"
                                }
                            ]}
                        />
                    </Form.Item>
                    <Form.Item label={"Cena"}  name={"price"} rules={[{required: true}]}>
                        <InputNumber step={1} />
                    </Form.Item>
                    <Form.Item label={"Cena sms"}  name={"smsPrice"} rules={[{required: true}]}>
                        <InputNumber step={0.01} />
                    </Form.Item>
                    <Form.Item label={"Początek rezerwacji"}  name={"start"} rules={[{required: true}]}>
                        <DatePicker />
                    </Form.Item>
                </Form>
            </Modal>
            <Button onClick={showModal} type="primary"><PlayCircleOutlined />Przypisz numer</Button>
        </>
    )
}

export default DedicatedNumbersConfirmModal