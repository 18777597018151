import {gql} from "@apollo/client";

const GET_COUNT_TOPUPS_STATS = gql`
query($months:Int, $dataPrefix:String){
  salesman{
    getCountTopUpsStats(months:$months, dataPrefix:$dataPrefix){
      date,
      start
      manual
      normal
      canceled
    }
  }
}
`

export default GET_COUNT_TOPUPS_STATS