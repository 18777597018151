import {Switch} from "antd";
import {useEffect, useState} from "react";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ENABLE_DISABLE_MODULE from "../../gql/mutations/ENABLE_DISABLE_MODULE";
import {useMutation} from "@apollo/client";

type props_type = {
    refetch: any
    office_modules: any
    module:{
        id: string,
        name: string,
        techName: string,
        enabled: Boolean|undefined
    }
    officeId: string|undefined
}
const OfficeModulesSwitchComponent = ({refetch, office_modules, module, officeId}:props_type) =>{
    const [enable, setEnable] = useState(false)
    const [setModuleMutation, {data: set_module_mutation_data, loading, error}] = useMutation(ENABLE_DISABLE_MODULE)

    useEffect(() => {
        if(office_modules){
            let enabled = false
            const officeEnabledModules_tmp = office_modules.salesman.getMyOffice.modulepriceSet.edges
            enabled = officeEnabledModules_tmp.find((office_module:any) => module.id === office_module.node.module.id)
            if(!enabled)
                setEnable(false)
            else(
                setEnable(true)
            )

        }
    }, [office_modules]);

    const handleSwitch = (e:boolean) =>{
        setModuleMutation({
                variables:{
                    input: {
                        "officeId": officeId,
                        "moduleId": module.id,
                        "enabled": e
                    }
                }
            }
        )
        setEnable(e)
    }

    useEffect(() => {
        refetch()
    }, [set_module_mutation_data]);

    return(
        <>
            <Switch
                checked={enable}
                onChange={handleSwitch}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
            />
        </>
    )
}

export default OfficeModulesSwitchComponent