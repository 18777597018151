import Cookies from "universal-cookie";
import {useNavigate} from "react-router";
import {useEffect} from "react";

const Logout = () =>{
    const cookies = new Cookies()
    const navigate = useNavigate()

    useEffect(() => {
        logoutUserHandler()
    }, []);
    const logoutUserHandler = () =>{
        localStorage.removeItem('token')
        cookies.remove('token')
        navigate("/login")
    }

    return <>Trwa wylogowywanie</>
}

export default Logout