import {EditOutlined} from "@ant-design/icons";
import {Button, Modal, InputNumber, Select, message} from "antd";
import {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import CHANGE_OFFICE_PAYMENT_MUTATION from "../../gql/mutations/CHANGE_OFFICE_PAYMENT_MUTATION";

const OfficeChangePaymentType = ({office_data, refetch_office}:{office_data:any, refetch_office:any}) =>{
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [paymentType, setPaymentType] = useState(office_data.paymentType)
    const [postpaidLimit, setPostpaidLimit] = useState<number|null>(0)
    const [changePaymentTypeMutation, {data:data_mutation, loading, error}] = useMutation(CHANGE_OFFICE_PAYMENT_MUTATION)


    useEffect(() => {
        if(data_mutation){
            message.success(`Zmieniono typ konta na ${paymentType}`)
            refetch_office()
        }
    }, [data_mutation]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
        changePaymentTypeMutation({
            variables:{
                input:{
                    "officeId": office_data.id,
                    "paymentType": paymentType,
                    "defaultLimit": postpaidLimit,
                }
            }
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChangePaymentType = (e:string) =>{
        setPaymentType(e)
    }

    const handleChangeLimit = (e:number|null) =>{
        setPostpaidLimit(e)
    }

    return (
        <>
            <Button onClick={showModal}><EditOutlined /></Button>
            <Modal title="Ustawienia rodzaju płatności POSTPAID" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Select
                    style={{width:"100%"}}
                    defaultValue={office_data.paymentType}
                    options={[
                        {value: "PREPAID", label: "PREPAID"},
                        {value: "POSTPAID", label: "POSTPAID"},
                    ]}
                    onChange={handleChangePaymentType}
                />
                <InputNumber onChange={handleChangeLimit} disabled={paymentType!=="POSTPAID"} placeholder={"Wpisz miesięczny limit odnawialny ilościowy"} style={{width:"100%"}} />
            </Modal>

        </>
    )
}

export default OfficeChangePaymentType
