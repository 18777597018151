import {gql} from "@apollo/client";

const GET_FILTERED_WORKERS = gql`
query($multi: String){
  salesman {
    getFilteredWorkers(multi: $multi) {
    edges {
      node {
        id,
        firstname,
        lastname,
        mobile,
        office {
            id,
            name,
            shortname,
        }
        user {
            username,
            dateJoined,
            email,
        }
        role {
            name,
        }
      }
    }
    }
  }
}
`
export default GET_FILTERED_WORKERS