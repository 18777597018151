import {gql} from "@apollo/client";

const GET_OFFICES_STATS = gql`
query{
  salesman{
    getActiveOfficesCount
    getTestingOfficesCount
    getAllOfficesCount
  }
}
`

export default GET_OFFICES_STATS;