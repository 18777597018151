import {Button, InputNumber, message, Modal, Select} from "antd";
import {useState, useEffect} from "react";
import {EditOutlined} from "@ant-design/icons";
import {useMutation} from "@apollo/client";
import CHANGE_OFFICE_STATUS_MUTATION from "../../gql/mutations/CHANGE_OFFICE_STATUS_MUTATION";

const OfficeChangeStatus = ({office_data, refetch_office}:any) =>{
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [officeStatus, setOfficeStatus] = useState(office_data.status)
    const [changeOfficeStatusMutation, {data:data_mutation, loading,error}] = useMutation(CHANGE_OFFICE_STATUS_MUTATION)

    useEffect(() => {
        if(data_mutation){
            message.success(`Zmieniono status konta na ${officeStatus}`)
            refetch_office()
        }
    }, [data_mutation]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () =>{
        setIsModalOpen(false);
        changeOfficeStatusMutation({
            variables:{
                input:{
                    "officeId":office_data.id,
                    "status": officeStatus
                }
            }
        })
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChangeStatus = (e:string) =>{
        setOfficeStatus(e)
    }

    return(
        <>
            <Button onClick={showModal}><EditOutlined /></Button>
            <Modal title="Zmień status konta" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Select
                    style={{width:"100%"}}
                    defaultValue={office_data.status}
                    options={[
                        {value: "ACTIVE", label: "AKTYWNY"},
                        {value: "INACTIVE", label: "NIEAKTYWNY"},
                        {value: "TESTING", label: "TESTOWY"},
                    ]}
                    onChange={handleChangeStatus}
                />
            </Modal>
        </>
    )
}

export default OfficeChangeStatus