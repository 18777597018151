import React, {useContext, useEffect, useState} from "react";
import {Breadcrumb, Button, Input, Layout, Table, theme} from 'antd';
import {useQuery} from "@apollo/client";
import GET_UNASSIGNED_OFFICES from "../../gql/queries/GET_UNASSIGNED_OFFICES";
import type { ColumnsType } from 'antd/es/table';
import {UserContext} from "../../contexts/UserContext.context";
import {Link} from "react-router-dom";
import {EditOutlined} from "@ant-design/icons";
import GET_OFFICE_TYPES from "../../gql/queries/GET_OFFICE_TYPES";

const {Content } = Layout;

type officeType = {
    "node": {
        "__typename": string,
        "id": string,
        "status": string,
        "name": string,
        "displayName": string,
        "nip": string|null,
        "country": string|null,
        "street": string|null,
        "zip": string|null,
        "city": string|null,
        "telephone": string|null,
        "fax": string|null,
        "email": string,
        "mobile": string,
        "logo": string,
        "registrationTemplate": string,
        "postRegistrationTemplate": string,
        "shortname": string,
        "createdAt": string,
        "paymentType": string,
        "accountBalance": string,
    }
}

const UnassignedOfficesView = () =>{

    const [searchString, setSearchString] = useState("")
    const [offices, setOffices] = useState<officeType[]>([])
    const [allOffices, setAllOffices] = useState<officeType[]>([])
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [countRecords, setCountRecords] = useState(0)
    const [statusFilter, setStatusFilter] = useState<any>("")
    const [officeTypeFilter, setOfficeTypeFilter] = useState<String|null| boolean|number>("")
    const [officePaymentTypeFilter, setOfficePaymentTypeFilter] = useState<any>("")
    const [officeTypes, setOfficeTypes] = useState<any[]>([])
    const {data: officesData, loading:officesLoading, error:officesError} = useQuery(GET_UNASSIGNED_OFFICES, {variables: {"multi":searchString, "first":pageSize, "offset": page, "status": statusFilter, "officeType": officeTypeFilter, "paymentType":officePaymentTypeFilter}})
    const {data: officesTypesData, loading: officesTypesLoading, error: officeTypeserror} = useQuery(GET_OFFICE_TYPES)
    const {salesman, setSalesman} = useContext(UserContext)


    useEffect(() => {
        if(officesData){
            setOffices(officesData.salesman.getUnassignedOffices.edges)
            setAllOffices(officesData.salesman.getUnassignedOffices.edges)
            setCountRecords(officesData.salesman.getUnassignedOffices.totalCount)
        }
    }, [officesData]);

    useEffect(()=>{
        if(officesTypesData){
            let officeTypes_tmp:any[] = []
            officesTypesData.salesman.getOfficeTypes.map(
                (officeType:any)=>{
                    officeTypes_tmp.push(
                        {
                            value: officeType.codename,
                            text: officeType.otype
                        }
                    )
                }
            )
            setOfficeTypes(officeTypes_tmp)

        }
    },[officesTypesData])

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    let table_columns: ColumnsType<any> = [
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_, record) =><>{record.node.status}</>,
            filters:[
                {
                    text: "NEW",
                    value: "NEW"
                },
                {
                    text: "ACTIVE",
                    value: "ACTIVE"
                },
                {
                    text: "REMOVED",
                    value: "REMOVED"
                },
            ],
            filterMultiple: false,
        },
        {
            title: "Nazwa klienta",
            dataIndex: "displayName",
            key: "displayName",
            render: (_, record) =><>{record.node.displayName}</>
        },
        {
            title: "Kod konta",
            dataIndex: "shortname",
            key: "shortname",
            render: (_, record) =><>{record.node.shortname}</>
        },
        {
            title: "Data utworzenia",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (_, record) =><>{record.node.createdAt}</>
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (_, record) =><>{record.node.email}</>
        },
        {
            title: "Rodzaj płatności",
            dataIndex: "paymentType",
            key: "paymentType",
            render: (_, record) =><>{record.node.paymentType}</>,
            filters:[
                {
                    text: "PREPAID",
                    value: "PREPAID"
                },
                {
                    text: "POSTPAID",
                    value: "POSTPAID"
                },
            ],
            filterMultiple: false,
        },
        {
            title: "Typ klienta",
            dataIndex: "officeType",
            key: "officeType",
            render: (_, record) =><>{record.node.officeType.otype}</>,
            filters:officeTypes,
            filterMultiple: false,
        },
        {
            title: "Stan konta",
            dataIndex: "accountBalance",
            key: "accountBalance",
            render: (_, record) =><>{record.node.accountBalance}</>
        },
    ]

    if(salesman.supervisor===true){
        table_columns.push({
            title: "Akcje",
            dataIndex: "actions",
            key: "actions",
            render: (_:any,record:any) => <>
                <Link to={`/office_edit/${record.node.id}`}>
                    <Button type={"primary"}>
                        <EditOutlined />
                    </Button>
                </Link>
            </>
        })
    }

    return(
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Klienci nie przypisani</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
                <Input placeholder={"Wyszukaj klienta"} onChange={(e)=>setSearchString(e.target.value)} />
                <Table
                    columns={table_columns}
                    dataSource={offices}
                    loading={officesLoading}
                    onChange={
                        (pagination, filters, sorter, extra)=>{
                            if(filters){
                                if(filters['status']) {
                                    setStatusFilter(filters['status'][0])
                                }else {
                                    setStatusFilter("")
                                }
                                if(filters['officeType']) {
                                    setOfficeTypeFilter(filters['officeType'][0])
                                }else {
                                    setOfficeTypeFilter("")
                                }
                                if(filters['paymentType']){
                                    setOfficePaymentTypeFilter(filters['paymentType'][0])
                                }else{
                                    setOfficePaymentTypeFilter("")
                                }
                            }
                        }
                    }
                    pagination={{
                        total: countRecords,
                        pageSize: pageSize,
                        current: page+1,
                        onChange: (page, pageSize)=>{
                            setPage(page-1)
                            setPageSize(pageSize)
                        }
                    }}
                />
            </div>
        </Content>
    )
}

export default UnassignedOfficesView