import {gql} from "@apollo/client";

const GET_MY_OFFICE_GROUPS = gql`
query($id:ID!){
  salesman{
    getMyOffice(id:$id){
        groups{
          edges{
            node{
              id,
              name,
              countSubscribers
            }
          }
        }
    }
  }
}
`
export default GET_MY_OFFICE_GROUPS