import {theme, Tabs} from "antd";
import React from "react";
import DedicatedNumbersList from "../DedicatedNumbersList/DedicatedNumbersList.component";

import DedicatedNumbersCreatorReservation
    from "../DedicatedNumbersCreatorReservation/DedicatedNumbersCreatorReservation.component";

const DedicatedNumbersMain = () =>{

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return (
        <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
            <Tabs
            type="card"
            items={[
                {
                    label:"Lista numerów dedykowanych",
                    key:"dedicated_numbers_list",
                    children:<DedicatedNumbersList />
                },
                {
                    label:"Nowa rezerwacja",
                    key:"dedicated_numbers_creator",
                    children:<DedicatedNumbersCreatorReservation />
                }

            ]}
            />
        </div>
    )
}

export default DedicatedNumbersMain