import {gql} from "@apollo/client";

const GET_SALESMAN_DATA = gql`
query{
    salesman{
      getSalesmanData{
        id,
        firstname,
        lastname,
        email,
        mobile,
        supervisor,
        customerService,
        reservationSupervisor,
      }
  }
}
`

export default GET_SALESMAN_DATA;