import React, {useEffect, useState} from "react";
import {Breadcrumb, Layout, theme, Input, Select, Switch, Form, Table, Button, message, InputNumber} from 'antd';
import {useMutation, useQuery} from "@apollo/client";
import GET_ALL_MODULES from "../../gql/queries/GET_ALL_MODULES";
import OfficeModulesSwitchComponent from "../../components/OfficeModulesSwitch/OfficeModulesSwitch.component";
import OfficeExpandSettings from "../../components/OfficeExpandSettings/OfficeExpandSettings.component";
import GET_AFFILIATE from "../../gql/queries/GET_AFFILIATE";
import GET_OFFICE_TEMPLATES from "../../gql/queries/GET_OFFICE_TEMPLATES";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import GET_OFFICE_TYPES from "../../gql/queries/GET_OFFICE_TYPES";
import CREATE_OFFICE_MUTATION from "../../gql/mutations/CREATE_OFFICE_MUTATION";
const { Content} = Layout;

type module_type = {
    id: string,
    name: string,
    techName: string,
    enabled: Boolean|undefined,
}

type affiliate_type = {
    email: string,
    id: string,
    isActive: boolean,
    name: string
}

type office_template_type={
    id: string,
    modules: module_type[],
    partnerCode: string,
    routeName: string,
    startingBalance: number
}

type select_options_type = {
    label: string
    value: string
}

const default_all_modules = {
    "voting_cards": false,
    "remote_group_send": false,
    "mail2sms": false,
    "course": false,
    "mailing": false,
    "demo_workplace": false,
    "smsregister": false,
    "santander": false,
    "techmodule": false,
    "mail_checker": false,
    "dynamo": false,
    "incoming_messages": false,
    "mam": false,
    "individual_send": false,
    "smyk": false,
    "contacts": false,
    "sms_actions": false,
    "csv": false,
    "surveys": false,
    "public_api": false,
    "votings": false,
    "send_plus": false,
    "simple_send": false,
    "calendar_send": false,
    "votings_basic": false,
    "edbms_promo": false,
    "shortname": false
}

const default_checked_modules = {
    mail2sms: true,
    course: true,
    mailing:true,
    smsregister: true,
    incoming_messages: true,
    contacts: true,
    sms_action: true,
    csv: true,
    votings: true
}

const CreateOfficeView = () =>{
    const [officeModules, setModules] = useState<module_type[]>([])
    const [affiliate, setAffiliate] = useState<select_options_type[]>([])
    const [officeTemplates, setOfficeTemplates] = useState<select_options_type[]>([])
    const [officeTypes, setOfficeTypes] = useState<select_options_type[]>([])
    const [initialValues, setInitialValues] = useState({})
    const [initialModuleValues, setInitialModuleValues] = useState(Object.assign(default_all_modules, default_checked_modules))


    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [form] = Form.useForm()
    const [formModules] = Form.useForm()

    const {data:all_modules_data, loading:all_modules_loading, error: all_modules_error} = useQuery(GET_ALL_MODULES)
    const {data: affiliate_data, loading: affiliate_loading, error: affiliate_error} = useQuery(GET_AFFILIATE)
    const {data: office_templates_data, loading: office_templates_loading, error: office_templates_error} = useQuery(GET_OFFICE_TEMPLATES)
    const {data: office_types_data, loading: office_types_loading, error: office_types_errors} = useQuery(GET_OFFICE_TYPES)
    const [createOfficeMutate, {data: create_office_mutation_data, loading:create_office_mutation_loading, error:create_office_mutation_error}] = useMutation(CREATE_OFFICE_MUTATION)

    const columns_table = [
        {
            title: "Nazwa modułu",
            dataIndex: "name",
            render:(_:any,record:module_type)=><>{record.name}</>
        },
        {
            title: "Akcje",
            dataIndex: "action",
            render:(_:any,record:module_type)=><Switch />
        },
    ]

    const expandable_modules_tech_names = [
        "voting_cards",
        "votings",
    ]

    useEffect(() => {
        if(all_modules_data){
            setModules(all_modules_data.salesman.getAllModules)
        }
    }, [all_modules_data]);

    useEffect(() => {
        if(office_templates_data){
            let office_templates_tmp:select_options_type[] = []
            office_templates_data.salesman.getOfficeTemplates.map(
                (office_template:any)=>{
                    office_templates_tmp.push({
                        label: office_template.partnerCode,
                        value: office_template.id
                    })
                }
            )
            setOfficeTemplates(office_templates_tmp)
        }
    }, [office_templates_data]);

    useEffect(() => {
        if(affiliate_data){
            let affiliate_tmp:select_options_type[] = []
            affiliate_data.salesman.getAffiliate.map(
                (affiliate:affiliate_type)=>{
                    affiliate_tmp.push({
                        label: affiliate.name,
                        value: affiliate.id
                    })
                }
            )
            setAffiliate(affiliate_tmp)
        }
    }, [affiliate_data]);

    useEffect(()=>{
        form.setFieldsValue(initialValues)
    }, [initialValues])

    useEffect(() => {
        formModules.setFieldsValue(initialModuleValues)
    }, [initialModuleValues]);

    useEffect(() => {
        if(office_types_data){
            let office_types_tmp:select_options_type[] = []
            office_types_data.salesman.getOfficeTypes.map(
                (office_type:any)=>{
                    office_types_tmp.push(
                        {
                            value: office_type.id,
                            label: office_type.otype
                        }
                    )
                }
            )
            setOfficeTypes(office_types_tmp)
        }

    }, [office_types_data]);

    useEffect(()=>{
        if(create_office_mutation_data){
            if(create_office_mutation_data.createOfficeMutation.created){
                message.success("Konto zostało utworzone")
                setInitialValues({})
                setInitialModuleValues(Object.assign(default_all_modules, default_checked_modules))
                formModules.resetFields()
                form.resetFields()
            }
        }
    },[create_office_mutation_data])

    const officeTemplateChangeHandler = (e:string) =>{
        let initialModuleValues_tmp:any = {...default_all_modules}
        let initialValues_tmp:any ={...initialValues}
        let office_template_selected = office_templates_data.salesman.getOfficeTemplates.find(
            (obj:any)=> {
                return e===obj.id
            }
        )
        office_template_selected.modules.edges.map(
            (module:any)=>{
                initialModuleValues_tmp[module.node.techName] = true
            }
        )

        initialValues_tmp.startingBalance = office_template_selected.startingBalance
        initialValues_tmp.officeTemplate = e
        initialValues_tmp.affiliate = office_template_selected.affiliate.id
        setInitialValues(initialValues_tmp)
        setInitialModuleValues(initialModuleValues_tmp)
    }

    const submitForm = () =>{
        form.validateFields().then(
            (res)=>{
                let payload_gql = form.getFieldsValue()
                let enabledModules = formModules.getFieldsValue()
                payload_gql['enabledModules'] = JSON.stringify(enabledModules)
                createOfficeMutate({variables:{
                    input: payload_gql
                    }
                })
            }
        ).catch(
            (errorInfo)=>{
                console.log(errorInfo)
                message.error("Brakuje danych, uzupełnij brakujące dane w formularzu")
            }
        )
    }

    return(
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Tworzenie konta</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
                <h1>Tworzenie konta</h1>
                <Form
                    labelCol={{ span: 4 }}
                    initialValues={initialValues}
                    form={form}
                >
                    <Form.Item label="Partner afiliacyjny" name={"affiliate"}>
                        <Select options={affiliate}/>
                    </Form.Item>
                    <Form.Item label="Szablon konta" name={"officeTemplate"}>
                        <Select options={officeTemplates} onChange={officeTemplateChangeHandler}/>
                    </Form.Item>
                    <Form.Item label="Nazwa firmy/organizacji" rules={[{ required: true }]} name={"name"}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Login użytkownika" rules={[{ required: true }]} name={"userLogin"} >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Nazwa skrócona" rules={[{ required: false }]} name={"shortname"}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Adres email" rules={[{ required: true }]} name={"email"}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Telefon komórkowy" rules={[{ required: true }]} name={"mobile"}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Status" rules={[{ required: true }]} name={"status"}>
                        <Select
                            options={
                            [
                                {
                                    label: "Aktywny",
                                    value: "ACTIVE"
                                },
                                {
                                    label: "Konto testowe",
                                    value: "TESTING"
                                }
                            ]
                            }
                        />
                    </Form.Item>
                    <Form.Item label="Typ klienta" rules={[{ required: true }]} name={"officeType"}>
                        <Select
                            options={officeTypes}
                        />
                    </Form.Item>
                    <Form.Item label="Kwota doładowania" name={"startingBalance"} rules={[{ required: true }]}>
                        <InputNumber  style={{width:"100%"}}/>
                    </Form.Item>
                    <Form.Item label="NIP" rules={[{ required: true }]} name={"nip"}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Kraj" rules={[{ required: true }]} name={"country"}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Ulica" rules={[{ required: true }]} name={"street"}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Kod pocztowy" rules={[{ required: true }]} name={"zip"}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Miasto" rules={[{ required: true }]} name={"city"}>
                        <Input />
                    </Form.Item>
                </Form>
                <Form
                labelCol={{ span: 4}}
                form={formModules}
                initialValues={initialModuleValues}
                >
                    {officeModules&&
                        officeModules.map((module)=> <Form.Item valuePropName={"checked"} name={module.techName} label={module.name}>
                            <Switch />
                        </Form.Item>
                        )
                    }
                    <Button onClick={submitForm}>Utwórz</Button>
                </Form>
            </div>
        </Content>
    )
}

export default CreateOfficeView