import React from "react";
import { Breadcrumb, Layout, theme } from 'antd';
import DashboardOfficeCounter from "../../components/DashboardOfficeCounter/DashboardOfficeCounter.component";
import DashboardOfficesStatistics
    from "../../components/DashboardOfficesStatistics/DashboardOfficesStatistics.component";
const { Content} = Layout;
const DashboardView = () =>{
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return(
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
                    <DashboardOfficeCounter />
                    <hr/>
                    <DashboardOfficesStatistics />
            </div>
        </Content>
    )
}

export default DashboardView