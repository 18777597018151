import { Button, Col, Row, Statistic } from 'antd';
import {useQuery} from "@apollo/client";
import GET_OFFICES_STATS from "../../gql/queries/GET_OFFICES_STATS";
import {useEffect, useState} from "react";

const DashboardOfficeCounter = () =>{

    const {data, loading, error} = useQuery(GET_OFFICES_STATS)
    const [activeOffices, setActiveOffices] = useState(0)
    const [testingOffices, setTestingOffices] = useState(0)
    const [allOffices, setAllOffices] = useState(0)

    useEffect(() => {
        if(data){
            setActiveOffices(data.salesman.getActiveOfficesCount)
            setTestingOffices(data.salesman.getTestingOfficesCount)
            setAllOffices(data.salesman.getAllOfficesCount)
        }
    }, [data]);

    return(
        <div>
            <h2>Liczba kont</h2>
            <Row gutter={16}>
                <Col span={12}>
                    <Statistic title="Aktywne" value={`${activeOffices} (${Math.round(activeOffices/allOffices*100)}%)` } />
                </Col>
                <Col span={12}>
                    <Statistic title="Testowe" value={`${testingOffices} (${Math.round(testingOffices/allOffices*100)}%)` } />
                </Col>
            </Row>
        </div>
    )
}
export default DashboardOfficeCounter