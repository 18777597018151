import {createBrowserRouter} from 'react-router-dom'
import DashboardView from "../views/DashboardView/DashboardView";
import MainLayout from "../layouts/Main/Main.layout";
import MyOfficesView from "../views/MyOfficesView/MyOfficesView";
import Login from "../layouts/Login/Login";
import AllOfficesView from "../views/AllOfficesView/AllOfficesView";
import UnassignedOfficesView from "../views/UnassignedOfficesView/UnassignedOfficesView";
import BokView from "../views/BokView/BokView";
import ReservationsNumbersView from "../views/ReservationsNumbersView/ReservationsNumbersView";
import CreateOfficeView from "../views/CreateOfficeView/CreateOfficeView";
import CreateRouteView from "../views/CreateRouteView/CreateRouteView";
import DedicatedNumbersView from "../views/DedicatedNumbersView/DedicatedNumbersView";
import OfficeEditView from "../views/OfficeEditView/OfficeEditView";
import Logout from "../components/Logout/Logout.component";
export const privateRouter = createBrowserRouter([
    {
        path:"/login",
        element: <Login />
    },
    {
        path: "/logout",
        element: <Logout />
    },
    {
        path:"/",
        element: <MainLayout/>,
        children:[
            {
                path:"/",
                element: <DashboardView/>
            },
            {
                path:"myoffices",
                element: <MyOfficesView />
            },
            {
                path:"alloffices",
                element: <AllOfficesView />
            },
            {
                path:"unassignedoffices",
                element: <UnassignedOfficesView />
            },
            {
                path:"bok",
                element: <BokView />
            },
            {
                path: "reservations_numbers",
                element: <ReservationsNumbersView/>
            },
            {
                path: "create_office",
                element: <CreateOfficeView />
            },
            {
                path: "create_route",
                element: <CreateRouteView />
            },
            {
                path: "dedicated_numbers",
                element: <DedicatedNumbersView />
            },
            {
                path: "office_edit/:officeId",
                element: <OfficeEditView />
            }
        ]
    }
])