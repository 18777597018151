import {gql} from "@apollo/client";

const GET_REGISTRATION_BY_PAYMENT = gql`
query($months: Int){
  salesman{
    getRegistrationByPayment(months:$months){
      date,
      postpaid,
      prepaid
    }
  }
}
`

export default GET_REGISTRATION_BY_PAYMENT;