import React, { useState, useEffect, useContext } from 'react';
import {
    DesktopOutlined,
    FontColorsOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
    UserSwitchOutlined,
    SaveOutlined,
    PlusSquareOutlined,
    PhoneOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {Layout, Menu, theme } from 'antd';
import {Outlet, useNavigate} from 'react-router'
import {Link} from "react-router-dom";
import Cookies from "universal-cookie";
import {useQuery, useLazyQuery} from "@apollo/client";
import GET_SALESMAN_DATA from "../../gql/queries/GET_SALESMAN_DATA";
import {UserContext} from "../../contexts/UserContext.context";


const { Header, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const MainLayout: React.FC = () => {
    const cookies = new Cookies()
    const navigate = useNavigate()
    const token = localStorage.getItem('token');
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const {salesman, setSalesman} = useContext(UserContext)
    const [getSalesmanData,{data:salesman_data, error:salesman_error, loading:salesman_loading}] = useLazyQuery(GET_SALESMAN_DATA)

    useEffect(() => {
        if(!token){
            navigate("/login")
        }
        if(!salesman){
            getSalesmanData()
        }
    }, []);

    useEffect(() => {
        if(salesman_data){
            setSalesman(salesman_data.salesman.getSalesmanData)
        }else if (salesman_data==null && salesman.error){
            logoutUserHandler()
        }
    }, [salesman_data]);


    // TODO: Disabled items for development in the future
    const items: MenuItem[] = [
        {
            label: <Link to={"/"}>Strona główna</Link>,
            key: '1',
            icon:<DesktopOutlined />
        },
        {
            label: <Link to={"/myoffices"}>Moi klienci</Link>,
            key: '2',
            icon:<UserOutlined />
        },
        {
            label: <Link to={"/alloffices"}>Wszyscy klienci</Link>,
            key: '3',
            icon:<TeamOutlined />
        },
        {
            label: <Link to={"/unassignedoffices"}>Klienci nieprzypisani</Link>,
            key: '4',
            icon:<UserSwitchOutlined />
        },
        {
            label: <Link to={"/bok"}>BOK</Link>,
            key: '5',
            icon:<PieChartOutlined />
        },
        // {
        //     label: <Link to={"/reservations_numbers"}>Rezerwacje</Link>,
        //     // label: <>Rezerwacje</>,
        //     key: '6',
        //     // disabled: true,
        //     icon:<SaveOutlined />
        // },
        {
            label: <Link to={"/create_office"}>Zakładanie konta</Link>,
            key: '7',
            icon:<PlusSquareOutlined />
        },
        {
            label: <Link to={"/dedicated_numbers"}>Numer dedykowany</Link>,
            key: '8',
            icon:<PhoneOutlined />
        },
    ];
    const logoutUserHandler = () =>{
        localStorage.removeItem('token')
        cookies.remove('token')
        navigate("/login")
    }

    if(!token){
        return null
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="logo-vertical" style={{padding: "5px"}} >
                    <a href="/" className="logo"><img src="/static/img/n/navbar_logo.png" /></a>
                </div>
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="vertical" items={items} />
            </Sider>
            <Layout>
                <Header style={{ padding: 0, background: "#001529", display:"flex", color:"white", alignItems:"flex-end", flexDirection:"column" }}>
                    <div>
                        <span>Witaj {salesman.firstname} {salesman.lastname}!</span>
                        <a onClick={logoutUserHandler} style={{color: "white", fontSize:"2em", paddingRight:"2em"}}><LogoutOutlined /></a>
                    </div>
                </Header>
                <Outlet/>

                <Footer style={{ textAlign: 'center' }}>Comverga SP.Z.O.O. ©2023</Footer>
            </Layout>
        </Layout>
    );
};

export default MainLayout;