import {useQuery} from "@apollo/client";
import GET_COUNT_TOPUPS_STATS from "../../gql/queries/GET_COUNT_TOPUPS_STATS";
import {Select} from "antd";
import {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

type chart_data_type = {
    labels: any[]
    datasets: any[]

}



const DashboardSumTopupsStatistics = () =>{
    const [chartRange, setChartRange] = useState(12)
    const {data, loading, error, refetch} = useQuery(GET_COUNT_TOPUPS_STATS, {variables:{"months":chartRange, "dataPrefix":"sum"}})
    const [chartData, setChartData] = useState<chart_data_type>({labels: [], datasets: []})
    useEffect(() => {
        if(data){
            const top_ups_stats = data.salesman.getCountTopUpsStats
            let labels_tmp: any[] = []
            let dataset_count_start_tmp = []
            let dataset_count_normal_tmp:any[] = []
            let dataset_count_manual_tmp:any[] = []
            let dataset_count_canceled_tmp:any[] = []
            top_ups_stats.map((topup:any)=>{
                dataset_count_start_tmp.push(topup.start)
                dataset_count_normal_tmp.push(topup.normal)
                dataset_count_manual_tmp.push(topup.manual)
                dataset_count_canceled_tmp.push(topup.canceled)
                labels_tmp.push(topup.date)
            })
            const data_tmp = {
                labels: labels_tmp,
                datasets: [
                    {
                        label: "Wartość doładowań ręcznych",
                        data: dataset_count_manual_tmp,
                        backgroundColor: 'rgb(228,45,42)'
                    },
                    {
                        label: "Wartość doładowań klienckich (PayU)",
                        data: dataset_count_normal_tmp,
                        backgroundColor: 'rgb(66,139,202)'
                    },
                ]
            }
            setChartData(data_tmp)
        }
    }, [data]);

    useEffect(() => {
        refetch({variables:{"months":chartRange, "dataPrefix":"sum"}})
    }, [chartRange]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Wartości doładowań w podziale na miesiące (PLN)',
            },
        },
    };


    return(
        <>
            <Select
                style={{width: "10em"}}
                defaultValue={12}
                options ={[
                    {value: 6, label: "6 miesięcy"},
                    {value: 12, label: "12 miesięcy"},
                    {value: 24, label: "2 lata"},
                    {value: 36, label: "3 lata"},
                ]}
                onChange={setChartRange}
                />
            <Bar data={chartData} options={options} />
        </>
    )
}

export default DashboardSumTopupsStatistics