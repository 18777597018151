import React from "react";
import { Breadcrumb, Layout, theme } from 'antd';
import DedicatedNumbersMain from "../../components/DedicatedNumbersMain/DedicatedNumbersMain.component";

const {Content } = Layout;

const DedicatedNumbersView = () =>{

    return(
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Numer Dedykowany</Breadcrumb.Item>
            </Breadcrumb>
            <DedicatedNumbersMain />
        </Content>
    )
}

export default DedicatedNumbersView