import {gql} from "@apollo/client";

const CHANGE_OFFICE_SALESMAN_MUTATION = gql`
mutation($input: ChangeOfficeSalesmanInput!){
  changeOfficeSalesman(input:$input){
    changed
  }
}
`

export default CHANGE_OFFICE_SALESMAN_MUTATION