import {gql} from "@apollo/client";

const PREMIUM_ACCESS_MUTATION = gql`
mutation($input:PremiumAccessMutationInput!){
  premiumAccess(input:$input){
    changed
  }
}
`

export default PREMIUM_ACCESS_MUTATION