import {gql} from "@apollo/client";

const GET_REGISTRATION_BY_SOURCE = gql`
query($months: Int){
  salesman{
    getRegistrationBySource(months:$months){
      date,
      admin,
      www
    }
  }
}
`

export default GET_REGISTRATION_BY_SOURCE;