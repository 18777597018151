import {gql} from "@apollo/client";

const GET_ALL_COUNTRIES = gql`
query{
  salesman{
    getAllCountries {
        name,
        code,
    }
  }
}
`
export default GET_ALL_COUNTRIES