import React, { useState, FormEvent, useEffect } from 'react';
import {Form, Checkbox, Row, Col, Button, Spin, message} from "antd";
import {useQuery, useMutation} from "@apollo/client";
import GET_ALL_COUNTRIES from "../../gql/queries/GET_ALL_COUNTRIES";
import GET_OFFICE_ALLOWED_MESSAGE_DESTINATION from "../../gql/queries/GET_OFFICE_ALLOWED_MESSAGE_DESTINATION";
import CREATE_OR_UPDATE_ALLOWED_MESSAGE_DESTINATION from "../../gql/mutations/CREATE_OR_UPDATE_ALLOWED_MESSAGE_DESTINATION"


type props_type = {
    officeId: string|undefined
}

interface Country {
  code: string;
  name: string;
}

interface GetAllCountriesData {
  salesman: {
    getAllCountries: Country[];
  };
}

interface AllowedMessageDestination {
  office_id: string;
  landlineNumbers: boolean;
  allowedCountries: {
    name: string;
    code: string;
  }[];
}

interface CreateOrUpdateAllowedMessageDestinationData {
  createOrUpdateAllowedMessageDestination: {
    allowedMessageDestination: AllowedMessageDestination;
  };
}

interface CreateOrUpdateAllowedMessageDestinationVars {
  input: {
    id?: string;
    landlineNumbers: boolean;
    allowedCountries: string[];
  };
}


const OfficeLimitations = ({officeId}:props_type) => {
  // Load countries choices
  const {
    loading: loading_office_limitations,
    error: error_office_limitations,
    data: data_office_limitations,
  } = useQuery<GetAllCountriesData>(GET_ALL_COUNTRIES);

  // Load allowed destination
  const {
    loading: loading_allowed_destination,
    error: error_allowed_destination,
    data: data_allowed_destination,
  } = useQuery(GET_OFFICE_ALLOWED_MESSAGE_DESTINATION, {variables: { "officeId":officeId },});

  // Load mutation
  const [createOrUpdateDestination, { loading: loading_update_destination, error: error_update_destination, data: data_update_destination }] = useMutation<
    CreateOrUpdateAllowedMessageDestinationData,
    CreateOrUpdateAllowedMessageDestinationVars
  >(CREATE_OR_UPDATE_ALLOWED_MESSAGE_DESTINATION);

  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [landlineNumbers, setLandlineNumbers] = useState<boolean>(true);

  // Initialize choices when data_allowed_destination are loaded
  useEffect(() => {
    if (data_allowed_destination) {
      const prevAllowedLandlineNumber = data_allowed_destination.salesman.getOfficeAllowedDestination?.landlineNumbers;
      const prevAllowedCountries = data_allowed_destination.salesman.getOfficeAllowedDestination?.allowedCountries?.map(
        (country: Country) => country.code
      ) || [];
      setLandlineNumbers(prevAllowedLandlineNumber);
      setSelectedCountries(prevAllowedCountries);
    }
  }, [data_allowed_destination]);

  // Show success message when mutation is finished
  useEffect(() => {
    if(data_update_destination){
        message.success("Zmiany zostały zapisane")
    }
  }, [data_update_destination]);

  const handleCountrySelection = (countryId: string) => {
    setSelectedCountries((prevSelected) =>
      prevSelected.includes(countryId)
        ? prevSelected.filter((id) => id !== countryId)
        : [...prevSelected, countryId]
    );
  };

  const handleSubmit = (e: FormEvent) => {
    createOrUpdateDestination({
      variables: {
        input: {
          id: officeId || undefined,
          landlineNumbers: landlineNumbers,
          allowedCountries: selectedCountries,
        },
      },
    }).catch(err => message.error('Mutation Error:', err))
  };

  if (loading_office_limitations) return <p>Loading countries...</p>;
  if (error_office_limitations) return <p>Error loading countries: {error_office_limitations.message}</p>;

  return (
    <div>
      <h3>Ograniczenia wysyłek przez mProfi</h3>
        <Form onFinish={handleSubmit} layout="vertical">
          {/* Checkbox for landline numbers */}
          <Form.Item>
            <Checkbox
              checked={landlineNumbers}
              onChange={(e) => setLandlineNumbers(e.target.checked)}
            >
              Wysyłki na numery stacjonarne
            </Checkbox>
          </Form.Item>

          {/* Country selection */}
          <Form.Item label="Zaznacz kraje do których klient będzie mógł wysyłać wiadomości:">
            <Row gutter={[16, 16]}>
              {data_office_limitations?.salesman.getAllCountries.map((country) => (
                <Col span={6} key={country.code}>
                  <Checkbox
                    value={country.code}
                    checked={selectedCountries.includes(country.code)}
                    onChange={() => handleCountrySelection(country.code)}
                  >
                    {country.name} ({country.code})
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Form.Item>

          {/* Submit Button */}
          <Form.Item>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button type="primary" htmlType="submit" disabled={loading_update_destination}>
                Zapisz zmiany {loading_update_destination && <Spin tip="Submitting..." />}
              </Button>
            </div>
          </Form.Item>
        </Form>
    </div>
);
};

export default OfficeLimitations