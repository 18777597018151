import {useContext, useState, useEffect} from "react";

import {Modal, Button, Select, message} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {UserContext} from "../../contexts/UserContext.context";
import {useQuery, useMutation} from "@apollo/client";
import GET_SALESMEN from "../../gql/queries/GET_SALESMEN";
import CHANGE_OFFICE_SALESMAN_MUTATION from "../../gql/mutations/CHANGE_OFFICE_SALESMAN_MUTATION";

const OfficeEditSalesmanModal = ({refetch_office,salesman_data,officeId}:any) =>{
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [salesmen, setSalesmen] = useState<any>([])
    const [newSalesman, setNewSalesman] = useState("")
    const [defaultValue, setDefaultValue] = useState("")
    const {salesman, setSalesman} = useContext(UserContext)
    const {data, loading, error} = useQuery(GET_SALESMEN)
    const [changeSalesman, {data: changeSalesman_data, loading:changeSalesman_loading, error:changeSalesman_error}] = useMutation(CHANGE_OFFICE_SALESMAN_MUTATION)

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        changeSalesman({variables:{input:{"officeId": officeId, "salesmanId":newSalesman}}})
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if(data) {
            let salesmen_tmp:any[] = []
            data.salesman.getSalesmen.map(
                (salesman_data:any)=>{
                    salesmen_tmp.push({
                        "value": salesman_data.id,
                        "label": `${salesman_data.firstname} ${salesman_data.lastname}`
                    })
                }
            )
            setSalesmen(salesmen_tmp)
        }
    }, [data]);

    useEffect(() => {
        if(salesman_data){
            setDefaultValue(salesman_data.id)
        }
    }, [salesman_data]);

    useEffect(() => {
        if(changeSalesman_data){
            message.success("Zmieniono salesmana")
            refetch_office()
        }
    }, [changeSalesman_data]);

    return(
        <>
            {salesman_data ? <> {salesman_data.firstname} {salesman_data.lastname}</>: <>Brak</>}
            <Button disabled={!salesman.supervisor} onClick={showModal}>
                <EditOutlined/>
            </Button>
            <Modal title="Zmień salesmana" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>
                    <Select
                        options={salesmen}
                        defaultValue={defaultValue}
                        style={{ width: "100%" }}
                        onChange={setNewSalesman}
                    />

                </p>
            </Modal>
        </>
    )
}

export default OfficeEditSalesmanModal