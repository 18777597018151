import { Tabs } from 'antd';
import DashboardCountTopupsStatistics from "../DashboardCountTopupsStatistics/DashboardCountTopupsStatistics.component";
import DashboardSumTopupsStatistics from "../DashboardSumTopupsStatistics/DashboardSumTopupsStatistics.component";
import DashboardAvgTopupsStatistics from "../DashboardAvgTopupsStatistics/DashboardAvgTopupsStatistics.component";
import DashboardRegistrationByPayment from "../DashboardRegistrationByPayment/DashboardRegistrationByPayment.component";
import DashboardRegistrationBySource from "../DashboardRegistrationBySource/DashboardRegistrationBySource.component";

const DashboardOfficesStatistics = () =>{
    return(
        <div>
            <h2>Statystyki</h2>
            <Tabs
                type="card"
                items={[
                    {
                        label: `Liczba doładowań`,
                        key: "1",
                        children: <DashboardCountTopupsStatistics />,
                    },
                    {
                        label: `Wartość doładowań`,
                        key: "2",
                        children: <DashboardSumTopupsStatistics />,
                    },
                    {
                        label: `Średnie wartości doładowań`,
                        key: "3",
                        children: <DashboardAvgTopupsStatistics />,
                    },
                    {
                        label: `Rejestracje - typ`,
                        key: "4",
                        children: <DashboardRegistrationByPayment />,
                    },
                    {
                        label: `Rejestracje - źródło`,
                        key: "5",
                        children: <DashboardRegistrationBySource />,
                    },
                ]}
            />
        </div>
    )
}

export default DashboardOfficesStatistics