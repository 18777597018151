import {useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import GET_MY_OFFICE_GROUPS from "../../gql/queries/GET_MY_OFFICE_GROUPS";


type props_type = {
    officeId: String|undefined
}

type group_type = {
    node:{
        countSubscribers: number,
        id: String,
        name: String

    }
}

const OfficeGroups = ({officeId}:props_type) =>{
    const {data, loading,error} = useQuery(GET_MY_OFFICE_GROUPS, {variables:{"id":officeId}})
    const [groups, setGroups] = useState<group_type[]>([])
    useEffect(() => {
        if(data){
            setGroups(data.salesman.getMyOffice.groups.edges)
        }
    }, [data]);

    return(
        <ul>
            {
                groups.map(
                    (group)=><li>{group.node.name} (Ilość: {group.node.countSubscribers})</li>
                )
            }
        </ul>
    )
}

export default OfficeGroups