import {gql} from "@apollo/client";

const GET_SALESMEN = gql`
query{
  salesman{
    getSalesmen{
      id,
      firstname,
      lastname,
      email,
    }
  }
}
`

export default GET_SALESMEN