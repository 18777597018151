import React,{useState, useEffect} from "react";
import {Breadcrumb, Layout, theme, Table, Button, Input} from 'antd';
import {useQuery} from "@apollo/client";
import {EditOutlined} from "@ant-design/icons"
import {Link} from "react-router-dom";
import GET_MY_OFFICES from "../../gql/queries/GET_MY_OFFICES";
import {ColumnsType} from "antd/es/table";
import GET_OFFICE_TYPES from "../../gql/queries/GET_OFFICE_TYPES";


const { Content } = Layout;

type officeType = {
            "node": {
            "__typename": string,
            "id": string,
            "status": string,
            "name": string,
            "displayName": string,
            "nip": string|null,
            "country": string|null,
            "street": string|null,
            "zip": string|null,
            "city": string|null,
            "telephone": string|null,
            "fax": string|null,
            "email": string,
            "mobile": string,
            "logo": string,
            "registrationTemplate": string,
            "postRegistrationTemplate": string,
            "shortname": string,
            "createdAt": string,
            "paymentType": string,
            "accountBalance": string,
        }
    }

const MyOfficesView = () =>{

    const [searchString, setSearchString] = useState("")

    const [offices, setOffices] = useState<officeType[]>([])
    const [allOffices, setAllOffices] = useState<officeType[]>([])
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [countRecords, setCountRecords] = useState(0)
    const [statusFilter, setStatusFilter] = useState<any>("")
    const [officeTypeFilter, setOfficeTypeFilter] = useState<String|null| boolean|number>("")
    const [officePaymentTypeFilter, setOfficePaymentTypeFilter] = useState<any>("")
    const [officeTypes, setOfficeTypes] = useState<any[]>([])
    const {data: officesData, loading:officesLoading, error:officesError} = useQuery(GET_MY_OFFICES, {variables: {"multi":searchString, "first":pageSize, "offset": page, "status": statusFilter, "officeType": officeTypeFilter, "paymentType":officePaymentTypeFilter}})
    const {data: officesTypesData, loading: officesTypesLoading, error: officeTypeserror} = useQuery(GET_OFFICE_TYPES)

    useEffect(() => {
        if(officesData){
            setOffices(officesData.salesman.getMyOffices.edges)
            setAllOffices(officesData.salesman.getMyOffices.edges)
            setCountRecords(officesData.salesman.getMyOffices.totalCount)
        }
    }, [officesData]);

    useEffect(()=>{
        if(officesTypesData){
            let officeTypes_tmp:any[] = []
            officesTypesData.salesman.getOfficeTypes.map(
                (officeType:any)=>{
                    officeTypes_tmp.push(
                        {
                            value: officeType.codename,
                            text: officeType.otype
                        }
                    )
                }
            )
            setOfficeTypes(officeTypes_tmp)

        }
    },[officesTypesData])

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const table_columns: ColumnsType<any> = [
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_, record) =><>{record.node.status}</>,
            filters:[
                {
                    text: "NEW",
                    value: "NEW"
                },
                {
                    text: "ACTIVE",
                    value: "ACTIVE"
                },
                {
                    text: "REMOVED",
                    value: "REMOVED"
                },
            ],
            filterMultiple: false,
        },
        {
            title: "Nazwa klienta",
            dataIndex: "displayName",
            key: "displayName",
            render: (_, record) =><>{record.node.displayName}</>
        },
        {
            title: "Kod konta",
            dataIndex: "shortname",
            key: "shortname",
            render: (_, record) =><>{record.node.shortname}</>
        },
        {
            title: "Data utworzenia",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (_, record) =><>{record.node.createdAt}</>
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (_, record) =><>{record.node.email}</>
        },
        {
            title: "Rodzaj płatności",
            dataIndex: "paymentType",
            key: "paymentType",
            render: (_, record) =><>{record.node.paymentType}</>,
            filters:[
                {
                    text: "PREPAID",
                    value: "PREPAID"
                },
                {
                    text: "POSTPAID",
                    value: "POSTPAID"
                },
            ],
            filterMultiple: false,
        },
        {
            title: "Typ klienta",
            dataIndex: "officeType",
            key: "officeType",
            render: (_, record) =><>{record.node.officeType.otype}</>,
            filters:officeTypes,
            filterMultiple: false,
        },
        {
            title: "Stan konta",
            dataIndex: "accountBalance",
            key: "accountBalance",
            render: (_, record) =><>{record.node.accountBalance}</>
        },
        {
            title: "Akcje",
            dataIndex: "actions",
            key: "actions",
            render: (_:any,record:any) => <>
                <Link to={`/office_edit/${record.node.id}`}>
                    <Button type={"primary"}>
                        <EditOutlined />
                    </Button>
                </Link>
            </>
        }
    ]

    return(
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Moi klienci</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
                <Input placeholder={"Wyszukaj klienta"} onChange={(e)=>setSearchString(e.target.value)} />
                <Table
                    loading={officesLoading}
                    columns={table_columns}
                    dataSource={offices}
                    onChange={
                        (pagination, filters, sorter, extra)=>{
                            if(filters){
                                if(filters['status']) {
                                    setStatusFilter(filters['status'][0])
                                }else {
                                    setStatusFilter("")
                                }
                                if(filters['officeType']) {
                                    setOfficeTypeFilter(filters['officeType'][0])
                                }else {
                                    setOfficeTypeFilter("")
                                }
                                if(filters['paymentType']){
                                    setOfficePaymentTypeFilter(filters['paymentType'][0])
                                }else{
                                    setOfficePaymentTypeFilter("")
                                }
                            }
                        }
                    }
                    pagination={{
                        total: countRecords,
                        pageSize: pageSize,
                        current: page+1,
                        onChange: (page, pageSize)=>{
                            setPage(page-1)
                            setPageSize(pageSize)
                        }
                    }}
                />
            </div>
        </Content>
    )
}

export default MyOfficesView