import {gql} from "@apollo/client";

const GET_CHANNELS_FOR_RESERVATIONS = gql`
query{
  salesman{
    getChannelsForReservations{
      id,
      name,
      msisdn,
      squadSet {
        edges {
          node {
            id,
            name
          }
        }
      },
      textRoute {
        id
      },
      mmsRoute{
        id
      },
      voiceFixedRoute{
        id
      },
      voiceMobileRoute{
        id
      }
      dedicatedReservations{
        edges{
          node{
            status
          }
        }
      }
    }
  }
}
`

export default GET_CHANNELS_FOR_RESERVATIONS