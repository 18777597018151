import {useQuery} from "@apollo/client";
import GET_REGISTRATION_BY_SOURCE from "../../gql/queries/GET_REGISTRATION_BY_SOURCE";
import {Select} from "antd";
import {useEffect, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

type chart_data_type = {
    labels: any[]
    datasets: any[]

}

const DashboardRegistrationBySource = () =>{
    const [chartRange, setChartRange] = useState(12)
    const {data, loading, error, refetch} = useQuery(GET_REGISTRATION_BY_SOURCE, {variables:{"months":chartRange}})
    const [chartData, setChartData] = useState<chart_data_type>({labels: [], datasets: []})


    useEffect(() => {
        if(data){
            const registration_source_stats = data.salesman.getRegistrationBySource
            let labels_tmp: any[] = []
            let dataset_www_tmp:any[] = []
            let dataset_admin_tmp:any[] = []
            registration_source_stats.map((registration_source:any)=>{
                    dataset_www_tmp.push(registration_source.www)
                    dataset_admin_tmp.push(registration_source.admin)
                    labels_tmp.push(registration_source.date)
            })

            const data_tmp = {
                labels: labels_tmp,
                datasets: [
                    {
                        label: "Zarejestrowani przez stronę WWW",
                        data: dataset_www_tmp,
                        backgroundColor: 'rgb(228,45,42)'
                    },
                    {
                        label: "Zarejestrowani przez administratorów",
                        data: dataset_admin_tmp,
                        backgroundColor: 'rgb(66,139,202)'
                    }
                ]
            }
            setChartData(data_tmp)
        }
    }, [data]);

    useEffect(() => {
        refetch({variables:{"months":chartRange}})
    }, [chartRange]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Średnie wartości doładowań w podziale na miesiące (PLN)',
            },
        },
    };


    return(
        <>
            <Select
                style={{width: "10em"}}
                defaultValue={12}
                options ={[
                    {value: 6, label: "6 miesięcy"},
                    {value: 12, label: "12 miesięcy"},
                    {value: 24, label: "2 lata"},
                    {value: 36, label: "3 lata"},
                ]}
                onChange={setChartRange}
            />
            <Bar data={chartData} options={options} />
        </>
    )
}

export default DashboardRegistrationBySource