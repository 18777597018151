import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Select, DatePicker, message } from 'antd';
import {useQuery, useMutation} from "@apollo/client";
import GET_OFFICES_FOR_RESERVATION_DEDICATED_NUMBERS
    from "../../gql/queries/GET_OFFICES_FOR_RESERVATION_DEDICATED_NUMBERS";
import CREATE_DEDICATED_RESERVATION_MUTATION from "../../gql/mutations/CREATE_DEDICATED_RESERVATION_MUTATION";
import {CopyOutlined, CalendarOutlined} from "@ant-design/icons"

const DedicatedNumbersCreatorReservationModal = ({channel_id, refetchChannels}:any) =>{
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {data, loading, error} = useQuery(GET_OFFICES_FOR_RESERVATION_DEDICATED_NUMBERS)
    const [offices, setOffices] = useState<any[]>([])

    const [createDedicatedReservation, {data:data_mutation, loading:loading_mutation, error:error_mutation}]= useMutation(CREATE_DEDICATED_RESERVATION_MUTATION)

    const [form] = Form.useForm()

    useEffect(() => {
        if(data){
            let data_tmp:any[] = []
            data.salesman.getOfficesForReservationDedicatedNumbers.map(
                (office:any)=>{
                    data_tmp.push(
                        {
                            label:office.displayName,
                            value: office.id
                        }
                    )
                }
            )
            setOffices(data_tmp)
        }
    }, [data]);
    useEffect(() => {
        if(data_mutation){
            if(data_mutation.createDedicatedReservation.created){
                message.success("Zarezerwowano numer")
                refetchChannels()
            }
        }
    }, [data_mutation]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        form.validateFields().then(
            (form_values)=>{
                createDedicatedReservation({variables:{input:form_values}})
                setIsModalOpen(false);
            }
        ).catch(
            (error)=>{
                console.log(error)
            }
        )
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button style={{marginLeft:"1em"}} type={"primary"} onClick={showModal}><CalendarOutlined /> Zarezerwuj</Button>
            <Modal
                title="Wstępna rezerwacja"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={1000}>
                <p>W tym miejscu możesz dokonać wstępnej rezerwacji numeru dedykowanego. Dzięki wstępnej rezerwacji numer zostanie przypisany do Twojego klienta i nie będzie dostępny dla innych sprzedawców. Póki numer nie zostanie aktywowany, klient nie będzie mógł korzystać z tego numeru ani nie zostanie obciążony opłatą.</p>
                <p>
                    <Form
                    labelCol={{span: 4}}
                    form={form}
                    >
                        <Form.Item label={"Klient"} name={"officeId"}>
                            <Select
                                options={offices}
                                showSearch
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            />
                        </Form.Item>
                        <Form.Item label={"Wstępna rezerwacja do"} name={"date"}>
                            <DatePicker style={{width: "100%"}} placeholder={"Wybierz datę"} />
                        </Form.Item>
                        <Form.Item name={"channelId"} hidden={true} initialValue={channel_id}>

                        </Form.Item>
                    </Form>
                </p>
            </Modal>
        </>
    )
}

export default DedicatedNumbersCreatorReservationModal