import {gql} from "@apollo/client";

const GET_MY_OFFICE_CHANNELS = gql`
query($id:ID!){
  salesman{
    getMyOffice(id:$id){
        channelgroupSet{
          edges{
            node{
              id,
              route{
                id,
                name,
                routeType
              },
              getPriceProperty
              channel{
                id,
                name,
              }
            }
          }
        }
        officeType{
          id,
          codename,
          otype
        }
    }
  }
}
`
export default GET_MY_OFFICE_CHANNELS