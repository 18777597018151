import React from "react";
import { Breadcrumb, Layout, theme } from 'antd';
const { Content} = Layout;
const CreateRouteView = () =>{
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    return(
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Tworzenie kanału</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
                Tworzenie kanału
            </div>
        </Content>
    )
}

export default CreateRouteView