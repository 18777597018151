import React, {useContext, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import { Breadcrumb, Layout, theme, Input, Table, Space, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import GET_FILTERED_WORKERS from "../../gql/queries/GET_FILTERED_WORKERS";
const { Content} = Layout;


type workerType = {
    "node": {
        "__typename": string,
        "id": string,
        "firstname": string,
        "lastname": string,
        "username": string,
        "mobile": string,
    }
}


const BokView = () =>{
    const [searchString, setSearchString] = useState("")

    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(10)
    const [countRecords, setCountRecords] = useState(0)

    const [workers, setWorkers] = useState<workerType[]>([])
    const {data: workersData, loading:workersLoading, error:workersError} = useQuery(GET_FILTERED_WORKERS, {variables:{"multi":searchString}})
    const { token: { colorBgContainer }, } = theme.useToken();


    useEffect(() => {
        if(workersData){
            setWorkers(workersData.salesman.getFilteredWorkers.edges)
            setCountRecords(workersData.salesman.getFilteredWorkers.totalCount)
        }
    }, [workersData]);



    let table_columns: ColumnsType<any> = [
        {
            title: "Klient (Kod konta)",
            dataIndex: "username",
            key: "username",
            render: (_:any,record:any)=><div><a href={`/office_edit/${record.node.office.id}`}>{record.node.office.name}</a> <p style={{width:"9em", color: "grey"}}>({record.node.office.shortname}) </p></div>
        },
        {
            title: "Login (Przypisana rola)",
            dataIndex: "username",
            key: "username",
            render: (_:any,record:any)=><div><p>{record.node.user.username} </p> <p style={{width:"9em", color: "grey"}}>({record.node.role.name})</p></div>
        },
        {
            title: "Nazwisko użytkownika",
            dataIndex: "full_name",
            key: "full_name",
            render: (_:any,record:any)=><p>{record.node.firstname} {record.node.lastname} </p>
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (_:any,record:any)=><p>{record.node.user.email} </p>
        },
        {
            title: "Telefon",
            dataIndex: "mobile",
            key: "mobile",
            render: (_:any,record:any)=><p>{record.node.mobile} </p>
        },
        {
            title: "Data utworzenia",
            dataIndex: "data_joined",
            key: "data_joined",
            render: (_:any,record:any)=><p>{record.node.user.dateJoined} </p>
        }
    ]


    return(
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>BOK</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
                <Input
                 placeholder={"Wpisz imię, nazwisko, numer telefonu albo adres email"} 
                 onChange={(e)=>setSearchString(e.target.value)}
                />
                <Table
                 columns={table_columns}
                 dataSource={workers}
                 rowKey={(record) => record.node.id} 
                 pagination={{
                    total: countRecords,
                    pageSize: pageSize,
                    current: page+1,
                    onChange: (page, pageSize)=>{
                        setPage(page-1)
                        setPageSize(pageSize)
                    }
                }}
                 
                 
                />
            </div>
        </Content>
    )
}

export default BokView