import {Select, Tabs} from "antd";
import GET_OFFICE_STATS from "../../gql/queries/GET_OFFICE_STATS";
import {useQuery} from "@apollo/client";
import {useState, useEffect} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

type chart_data_type = {
    labels: any[]
    datasets: any[]

}

type props_type = {
    officeId: String|undefined
}
const OfficeStatistics = ({officeId}:props_type) =>{

    const [messageType, setMessageType] = useState("SMS")
    const [chartData, setChartData] = useState<chart_data_type>({labels: [], datasets: []})
    const [chartRange, setChartRange] = useState(12)

    const {data, loading, error} = useQuery(GET_OFFICE_STATS, {variables:{"officeId": officeId, "messageType": messageType, "range": chartRange}})

    useEffect(() => {
        if(data){
            const office_stats = data.salesman.getOfficeStatistics
            let labels_tmp: any[] = []
            let dataset_count_count_tmp:any[] = []
            let dataset_count_parts_tmp:any[] = []
            let dataset_count_value_tmp:any[] = []
            office_stats.map(
                (stats:any) =>{
                    dataset_count_count_tmp.push(stats.count)
                    dataset_count_parts_tmp.push(stats.parts)
                    dataset_count_value_tmp.push(stats.value/1000)
                    labels_tmp.push(stats.date)
                }
            )
            const data_tmp = {
                labels: labels_tmp,
                datasets: [
                    {
                        label:"Ilość wysłanych wiadomości",
                        data: dataset_count_count_tmp,
                        backgroundColor: 'rgb(66,139,202)'
                    },
                    {
                        label:"Ilość wysłanych części",
                        data: dataset_count_parts_tmp,
                        backgroundColor: 'rgb(228,45,42)'
                    },
                    {
                        label:"Wartość wysłanych wiadomości",
                        data: dataset_count_value_tmp,
                        backgroundColor: 'rgb(105,105,105)'
                    },
                ]
            }
            setChartData(data_tmp)
        }
    }, [data]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: `Ilości wysłanych wiadomości ${messageType} w podziale na miesiące`,
            },
        },
    };

    return(
        <>
            <Select
                style={{width: "10em"}}
                defaultValue={"SMS"}
                value={messageType}
                options ={[
                    {value: "SMS", label: "SMS"},
                    {value: "MMS", label: "MMS"},
                    {value: "EMAIL", label: "E-mail"},
                ]}
                onChange={(val:string)=>setMessageType(val)}
            />
            <Select
                style={{width: "10em"}}
                defaultValue={12}
                value={chartRange}
                options ={[
                    {value: 6, label: "6 miesięcy"},
                    {value: 12, label: "12 miesięcy"},
                    {value: 24, label: "2 lata"},
                    {value: 36, label: "3 lata"},
                ]}
                onChange={setChartRange}
            />
            <Bar data={chartData} options={options} />
        </>
    )
}

export default OfficeStatistics