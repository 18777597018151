import {useEffect, useState} from "react";
import {Switch, DatePicker, Button, InputNumber} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {useMutation} from "@apollo/client";
import PREMIUM_ACCESS_MUTATION from "../../gql/mutations/PREMIUM_ACCESS_MUTATION";

const {RangePicker} = DatePicker

type props_type = {
    office_modules: any
    module:{
        id: String,
        name: String,
        techName: String,
        enabled: Boolean|undefined
    }
    officeId: String|undefined
}

type daily_cost_type = any

const OfficeExpandSettings = ({module, officeId, office_modules}: props_type) =>{
    const [enable, setEnable] = useState(false)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [dailyCost, setDailyCost] = useState<daily_cost_type>("")
    const [enabled, setEnabled] = useState(false)
    const [moduleName, setModuleName] = useState("")

    useEffect(() => {
        if(module.techName=='voting_cards'){
            setModuleName("Głosowania Zdalne")
        }

        if(module.techName=='votings'){
            setModuleName("Głosowania PRO")
        }
    }, []);
    useEffect(() => {
        if(office_modules){
            let enabled = false
            const officeEnabledModules_tmp = office_modules.salesman.getMyOffice.modulepriceSet.edges
            enabled = officeEnabledModules_tmp.find((office_module:any) => module.id === office_module.node.module.id)
            if(!enabled)
                setEnable(false)
            else(
                setEnable(true)
            )

        }
    }, [office_modules]);

    const [usePremiumAccessMutation, {data,loading,error}] = useMutation(PREMIUM_ACCESS_MUTATION)

    const handleChangeDateRange = (e:any) =>{
        setStartDate(e[0].$d)
        setEndDate(e[1].$d)
    }

    const useHandleSaveButton = () =>{
        usePremiumAccessMutation({variables:{
                input:{
                    "enabled": enabled,
                    "officeId": officeId,
                    "moduleId": module.id,
                    "dailyPrice": dailyCost,
                    "startDate": startDate,
                    "endDate": endDate
                }
            }})
    }

    if(!enable)
        return(
            <>
                Moduł jest nie włączony
            </>
        )

    if(module){
        return(
            <div>
                <h3>{moduleName}</h3>
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={enabled}
                    onChange={setEnabled}
                /><br/>
                <RangePicker disabled={!enabled} onChange={handleChangeDateRange} showTime /><br/>
                <InputNumber
                    disabled={!enabled}
                    min={0}
                    max={10000}
                    defaultValue={0}
                    style={{width:"10em"}}
                    onChange={(e)=>setDailyCost(e)}
                    stringMode={true}
                /> PLN/Dzień<br/>
                <Button onClick={useHandleSaveButton}>Zapisz</Button>
            </div>
        )
    }

    return (
        <>
        Brak dostępnych ustawień dla tego modułu
        </>
    )
}

export default OfficeExpandSettings