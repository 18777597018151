import {gql} from "@apollo/client";

const GET_AFFILIATE = gql`
query{
    salesman{
      getAffiliate{
        id,
        name,
        isActive,
        email,
      }
    }
}
`

export default GET_AFFILIATE