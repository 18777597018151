import {gql} from "@apollo/client";

const GET_OFFICE_STATS = gql`
query($officeId:ID!, $messageType:String!, $range:Int!){
  salesman{
    getOfficeStatistics(officeId:$officeId, messageType:$messageType, range:$range) {
      date
      count
      parts
      value
    }
  }
}
`
export default GET_OFFICE_STATS