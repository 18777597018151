import {gql} from "@apollo/client";

const TOKEN_AUTH = gql`
mutation TokenAuth($username: String!, $password: String!) {
  tokenAuth(username: $username, password: $password) {
    token
    payload
    refreshExpiresIn
  }
}
`

export default TOKEN_AUTH