import {gql} from "@apollo/client";

const GET_MY_OFFICE = gql`
query($id:ID!){
  salesman{
    getMyOffice(id:$id){
        id,
        status,
        name,
        displayName,
        nip,
        country,
        street,
         zip,
        city,
        telephone,
        fax,
        email,
        mobile,
        logo,
        registrationTemplate,
        postRegistrationTemplate,
        shortname,
        createdAt,
        paymentType,
        accountBalance
        showPricelist,
        showTopups,
        dedicated,
        dedicatedChannel{
            id,
            name,
            techName,
        }
        officeUsersCount,
        officeType{
          id,
          codename,
          otype
        },
        salesman{
          id,
          firstname,
          lastname,
          supervisor,
          customerService,
        }
    }
  }
}
`
export default GET_MY_OFFICE