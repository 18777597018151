import {useQuery} from "@apollo/client";
import {CheckOutlined, StopOutlined} from '@ant-design/icons'
import GET_MY_OFFICE from "../../gql/queries/GET_MY_OFFICE";
import {Descriptions} from "antd";
import type {DescriptionsProps} from 'antd'
import {useEffect, useState} from "react";
import OfficeChangePaymentTypeComponent from "../OfficeChangePaymentType/OfficeChangePaymentType.component";
import OfficeChangePaymentType from "../OfficeChangePaymentType/OfficeChangePaymentType.component";
import OfficeChangeStatus from "../OfficeChangeStatus/OfficeChangeStatus.component";
import OfficeEditSalesmanModal from "../OfficeEditSalesmanModal/OfficeEditSalesmanModal.component";
import {UserContext} from "../../contexts/UserContext.context";

type props_type = {
    officeId: String|undefined
}

const OfficeEditDetails = ({officeId}:props_type) =>{
    const {data:office_data, loading:office_loading, error:office_error, refetch: refetch_office} = useQuery(GET_MY_OFFICE, {variables:{"id":officeId}})

    const [officeData, setOfficeData] = useState<DescriptionsProps['items']>([])

    useEffect(() => {
        if(office_data){
            const o_data = office_data.salesman.getMyOffice
            setOfficeData([
                    {
                        label: 'Nazwa klienta (Nazwa wyświetlana)',
                        span:4,
                        children: `${o_data.email} (${o_data.displayName})`,
                    },
                    {
                        label: 'Kod konta',
                        span:4,
                        children: o_data.shortname,
                    },
                    {
                        label: 'Nip',
                        span:4,
                        children: o_data.nip,
                    },
                    {
                        label: 'Email',
                        span:4,
                        children: o_data.email,
                    },
                    {
                        label: 'Telefon komórkowy',
                        span:4,
                        children: o_data.mobile,
                    },
                    {
                        label: 'Data utworzenia',
                        span:4,
                        children: o_data.createdAt,
                    },
                    {
                        label: 'Status',
                        span:4,
                        children: <>{o_data.status} <OfficeChangeStatus office_data={o_data} refetch_office={refetch_office} /></>,
                    },
                    {
                        label: 'Numer dedykowany',
                        span:4,
                        children: <>{o_data.dedicated?<><CheckOutlined style={{color:"green"}} /> - {o_data.dedicatedChannel.name}</>: <StopOutlined style={{color:"red"}} />}</>,
                    },
                    {
                        label: 'Rodzaj płatności',
                        span:4,
                        children: <>{o_data.paymentType}<OfficeChangePaymentType office_data={o_data} refetch_office={refetch_office} /></>,
                    },
                    {
                        label: 'Ustawienia konta',
                        span:4,
                        children: <>
                            Cennik: {o_data.showPricelist?<CheckOutlined style={{color:"green"}} />: <StopOutlined style={{color:"red"}} />}<br/>
                            Doładowania: {o_data.showPricelist?<CheckOutlined style={{color:"green"}} />: <StopOutlined style={{color:"red"}} />}
                        </>,
                    },
                    {
                        label: 'Typ klienta',
                        span:4,
                        children: o_data.officeType.otype,
                    },
                    {
                        label: 'Ilość użytkowników',
                        span:4,
                        children: o_data.officeUsersCount,
                    },
                    {
                        label: "Sprzedawca",
                        span: 4,
                        children: <OfficeEditSalesmanModal refetch_office={refetch_office} key={officeId} salesman_data={o_data.salesman} officeId={officeId}/>
                    }
                ]
            )
        }
    }, [office_data]);

    return(
        <div>
            <Descriptions items={officeData} bordered={true} />
        </div>
    )
}

export default OfficeEditDetails